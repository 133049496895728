<template>
  <div>
    <choose-product edit />
  </div>
</template>

<script>
import chooseProduct from "../../../../components/createReport/chooseCompany/chooseProduct/index.vue";
export default {
  components: {
    chooseProduct,
  },
};
</script>

<style>
</style>